import React from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { empty } from "@nexstar-network/shared-utils";
import { toolData } from "../../home/utils/homePageHelpers";
import { useUserContext } from "../../context/user/UserContext";
import NavigationDropDown from "./NavigationDropDown";
import { userCanViewBPW } from "../../../utils/helpers/UserHelper";
import { useCompanyContext } from "../../context/company/CompanyContext";
import { useDirtyContext } from "../../context/dirty/DirtyContext";

type DesktopNavProps = {
  toolsToDisplay: any;
  toolsAccess: any;
};
export default function DesktopNav({
  toolsToDisplay,
  toolsAccess,
}: DesktopNavProps) {
  const { userToolsAccess, accessLoading, companiesLoading, getUser } =
    useUserContext();
  const { handleOpenDirty } = useDirtyContext();
  const { workshopEvents, fetchingBpwStatus } = useCompanyContext();
  const navigate = useNavigate();

  if (accessLoading !== false || companiesLoading !== false) return null;
  if (!userToolsAccess) {
    // TP-1128: BK checking for !userToolsAccess to prevent TypeError - Cannot read properties of null (reading 'COMPANYMANAGEMENT')
    return (
      <Popover.Group
        as="nav"
        className="hidden space-x-0 space-x-4 lg:flex mt-2"
      >
        <p className="text-white font-bold">No User Access</p>
      </Popover.Group>
    );
  }
  return (
    <Popover.Group as="nav" className="hidden space-x-0 space-x-3 xl:flex mt-2">
      {toolsAccess.COMPANYMANAGEMENT.access ? (
        <NavigationDropDown
          userToolsAccess={toolsAccess}
          relatedTools={["COMPANYMANAGEMENT", "IMPORT", "EXPORT", "REPORTS"]}
          title="Company Management"
          toolsToDisplay={toolsToDisplay}
          id="CompanyManagementDropdownNav"
        />
      ) : null}
      <NavigationDropDown
        userToolsAccess={toolsAccess}
        relatedTools={["THREEDAYCALL", "DAILYPACE"]}
        title="Daily Operations"
        toolsToDisplay={toolsToDisplay}
        id="CallCenterDropdownNav"
      />
      {toolsAccess.PROFITPRICER.access ? (
        <NavigationDropDown
          userToolsAccess={toolsAccess}
          relatedTools={[
            "PROFITPRICER",
            "WHATIFS",
            "PROFITREPORT",
            "FINANCIALSURVEY",
          ]}
          title="Financial"
          toolsToDisplay={toolsToDisplay}
          id="DailyToolsDropdownNav"
        />
      ) : null}
      {!empty(workshopEvents) &&
      userCanViewBPW(getUser()) &&
      toolsAccess.BPW.access ? (
        // BK TP-1314 temporarily disabling Business Planning nav button after they update department info. This prevents navigating to BPW while fetchingBpwStatus
        <button
          id="BusinessPlanningDashboardNav"
          disabled={fetchingBpwStatus}
          type="button"
          key={toolData.BPW.label}
          onClick={() => {
            handleOpenDirty(() => navigate(toolData.BPW.link));
          }}
          className={`inline-flex items-center p-1 bg-NexstarBlue-dark font-bold hover:text-gray-100  border-t-2 border-y-transparent border-b-2 focus-visible:border-b-white focus:outline-none focus:ring-0 focus:ring-offset-0 transition ${
            fetchingBpwStatus
              ? "opacity-40"
              : "opacity-100 hover:border-b-white"
          }`}
        >
          <p className="text-[15px] font-bold text-white">Business Planning</p>
        </button>
      ) : null}
      {toolsAccess.DMT.access ? (
        <NavigationDropDown
          userToolsAccess={toolsAccess}
          relatedTools={["DMT", "MPW"]}
          title="Marketing"
          toolsToDisplay={toolsToDisplay}
          id="MarketingDropdownNav"
        />
      ) : null}
    </Popover.Group>
  );
}
