import React, { ReactElement, useEffect, useState } from "react";
import CardHeader from "../../../utils/components/tailwind/CardHeader";
import CardBody from "../../../utils/components/tailwind/CardBody";
import CardWrapper from "../../../utils/components/tailwind/CardWrapper";
import Container from "../../../utils/components/tailwind/Container";
import { UseQueryType } from "../../../types/ErrorMessageType";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getNotificationsForMember,
  markAsRead,
} from "../utils/notificationRequests";
import InformationIcon from "../../../utils/components/icons/InformationIcon";
import ButtonToolTip from "../../../utils/components/tailwind/ButtonToolTip";
import { DateTime } from "luxon";
import { useUserContext } from "../../context/user/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  CompanyIdOrDefault,
  CRMUser,
  RoleIdOrDefault,
  UserIdOrDefault,
} from "../../../utils/helpers/UserHelper";
import NotificationToggle from "./NotificationToggle";
import LoadingCard from "../../../utils/components/tailwind/LoadingCard";
import { empty, Notification } from "@nexstar-network/shared-utils";
import {
  handleMarkSingleAsRead,
  markAllAsRead,
  orderedNotifications,
} from "../utils/notificationHelpers";
import { enqueueSnackbar } from "notistack";

export default function NotificationCenter(): ReactElement {
  const { getAccessTokenSilently } = useAuth0();
  const { getUser, selectedCompany } = useUserContext();
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const roleId = RoleIdOrDefault(user as CRMUser);
  const userId = UserIdOrDefault(user as CRMUser);
  const memberId = CompanyIdOrDefault(getUser(), selectedCompany).toString();
  const [displayOnlyRead, setDisplayOnlyRead] = useState<boolean>(false);
  const [numberOfUnread, setNumberOfUnread] = useState<number>(0);
  const [notifications, setNotifications] = useState<Array<Notification>>([]);
  const {
    data: notificationData,
    isLoading: notificationLoading,
    refetch,
    error: notificationsError,
  }: UseQueryType<Array<Notification>> = useQuery({
    queryKey: ["getNotifications"],

    queryFn: () =>
      getNotificationsForMember(
        memberId,
        roleId,
        userId,
        getAccessTokenSilently,
      ),
  });
  const markNotificationsAsRead = useMutation({
    mutationFn: (notificationsToMark: any) =>
      markAsRead(notificationsToMark, getAccessTokenSilently),
  });

  useEffect(() => {
    if (notificationData && notificationData && !notificationLoading) {
      let numberOfUnreadNotifications = 0;
      for (let i = 0; i < notificationData.length; i++) {
        if (empty(notificationData[i].readTime)) {
          numberOfUnreadNotifications++;
        }
      }
      setNumberOfUnread(numberOfUnreadNotifications);
      setNotifications(notificationData);
    }
  }, [notificationData]);

  const DisplayNotificationsForDashboard = (
    notificationsToDisplay: Notification[],
  ) => {
    if (notifications && notifications.length > 0 && !notificationLoading) {
      const displayItems: Notification[] = [];
      notificationsToDisplay.map((notification) => {
        if (displayOnlyRead) {
          if (empty(notification.readTime)) {
            displayItems.push(notification);
          }
        } else {
          displayItems.push(notification);
        }
      });
      if (displayItems.length) {
        return displayItems.map((notification) => (
          <>
            <div
              key={notification.notificationID}
              className="pt-4 pb-3 flex grow border-t-2 border-gray-100"
            >
              <button
                className="hover:bg-NexstarSoftGray text-gray-900 h-20 w-full items-center px-5 py-2 text-sm h-16 border-0"
                onClick={(ev) =>
                  handleMarkSingleAsRead(
                    ev,
                    notification.notificationID,
                    notifications,
                    setNotifications,
                    numberOfUnread,
                    setNumberOfUnread,
                    userId,
                    markNotificationsAsRead,
                    queryClient,
                    refetch,
                    false,
                    enqueueSnackbar,
                  )
                }
              >
                <div className="flex flex-col-3 justify-start">
                  <div className="pr-3 mt-[-2]">
                    <InformationIcon color="NexstarSkyBlue" size="medium" />
                  </div>
                  <div
                    className={`flex-row flex-wrap text-[17px] font-[450] mt-1 ${
                      !empty(notification.readTime)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    } w-full text-left justify-between`}
                  >
                    {notification.notificationText}
                  </div>
                  {empty(notification.readTime) ? (
                    <ButtonToolTip tooltip="Mark as read">
                      <button
                        className="h-[24px] w-[24px] hover:bg-NexstarGray-dark hover:rounded-full"
                        onClick={(ev) =>
                          handleMarkSingleAsRead(
                            ev,
                            notification.notificationID,
                            notifications,
                            setNotifications,
                            numberOfUnread,
                            setNumberOfUnread,
                            userId,
                            markNotificationsAsRead,
                            queryClient,
                            refetch,
                            true,
                            enqueueSnackbar,
                          )
                        }
                      >
                        <div className="bg-NexstarBlue-dark rounded-full w-[8px] h-[8px] font-bold text-white text-xs ml-2" />
                      </button>
                    </ButtonToolTip>
                  ) : (
                    <button className="h-[24px] w-[24px]">
                      <div className="bg-NexstarGray-dark rounded-full w-[8px] h-[8px] font-bold text-white text-xs ml-2" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col-1 justify-start">
                  <div
                    className={`flex-row w-full text-[12px] text-left ml-10 mt-1 ${
                      !empty(notification.readTime)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    }`}
                  >
                    Updated At:{" "}
                    <span>
                      {DateTime.fromISO(notification.created_at)
                        .toLocal()
                        .toFormat("MM-dd-yyyy")}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </>
        ));
      }
    }
    return (
      <div id="landing_page_no_notifications">
        <div className="h-[125px] pt-10 text-xl text-black text-center">
          No Notifications
        </div>
      </div>
    );
  };

  if (notificationsError) throw new Error(notificationsError.message);
  if (notificationLoading) return <LoadingCard />;
  return (
    <Container
      id="Notification_Main"
      data-testid="Notification_Main"
      padding="p-2"
      className="mb-7"
    >
      <CardWrapper data-testid="Notification_Center" id="Notification_Center">
        <div>
          <CardHeader
            title="Notification Center"
            bgColor="bg-NexstarGray"
            headingTextColor="text-NexstarBlue"
            border="border-b border-NexstarGray-dark"
            dataTestId="notification_center_Header"
            toolTipColor="gray"
          >
            <div className="justify-end text-black mr-3">
              <NotificationToggle
                onChange={() => setDisplayOnlyRead(!displayOnlyRead)}
                checked={displayOnlyRead}
                name="Mark All As Read"
                label="Only Show Unread"
              />
            </div>
          </CardHeader>
          <CardBody>
            {notifications && (
              <div className="flex text-sm justify-end mr-3 mt-[-10px] mb-2">
                <button
                  className="h-5 hover:text-NexstarOrange font-bold"
                  onClick={(ev) =>
                    markAllAsRead(
                      ev,
                      notifications,
                      setNotifications,
                      numberOfUnread,
                      setNumberOfUnread,
                      userId,
                      markNotificationsAsRead,
                      queryClient,
                      refetch,
                      enqueueSnackbar,
                    )
                  }
                >
                  Mark all as read
                </button>
              </div>
            )}
            {DisplayNotificationsForDashboard(
              orderedNotifications(notifications),
            )}
          </CardBody>
        </div>
      </CardWrapper>
    </Container>
  );
}
