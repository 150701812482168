import { useAuth0 } from "@auth0/auth0-react";
import React, { lazy, ReactElement, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Tooltip as ReactTooltip } from "react-tooltip";
import MaintenanceMode from "../../utils/components/MaintenanceMode";
import { CRMUser, noUserInProd } from "../../utils/helpers/UserHelper";
import Auth0ProviderWithHistory from "../auth/Auth0ProviderWithHistory";
import Auth0Wrapper from "../auth/Auth0Wrapper";
import { CompanyProvider } from "../context/company/CompanyContext";
import { UserProvider } from "../context/user/UserContext";
import ToolsFooter from "../navigation/ToolsFooter";
import "./app.css";
import ReleaseDialog from "../../utils/components/ReleaseDialog";
import ReleaseNotes from "../../utils/components/ReleaseNotes";
import ProtectedRoute from "../../utils/ProtectedRoute";
import EnvironmentWarning from "./components/EnvironmentWarning";
import { LoadingOverlay } from "../../utils/components/tailwind/LoadingOverlay";
import AppHeader from "../navigation/AppHeader";
import PageNotFound from "../../utils/components/tailwind/PageNotFound";
import NotificationCenter from "../notifications/components/NotificationCenter";
import { ErrorLandingPage } from "./components/ErrorLandingPage";
import { AppsEnum } from "@nexstar-network/shared-utils";
import { DirtyProvider } from "../context/dirty/DirtyContext";
import { BPWProvider } from "../context/bpw/BPWContext";
import { UAParser } from "ua-parser-js";
import UnsupportedBrowserLandingPage from "./components/UnsupportedBrowserLandingPage";

const HomePage = lazy(
  () =>
    import(/* webpackChunkName: "HomePage" */ "../home/components/HomePage"),
);

const ProfitPricerMain = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfitPricer" */ "../profitpricer/main/ProfitPricerMain"
    ),
);

const WhatIfsMain = lazy(
  () => import(/* webpackChunkName: "WhatIfs" */ "../whatifs/main/WhatIfsMain"),
);

const CompanyManagementMain = lazy(
  () =>
    import(
      /* webpackChunkName: "DepartmentManagement" */ "../management/main/CompanyManagementMain"
    ),
);
const ImportsMain = lazy(
  () => import(/* webpackChunkName: "Imports" */ "../imports/main/ImportsMain"),
);
const ExportsMain = lazy(
  () =>
    import(/* webpackChunkName: "Exporting" */ "../exporting/main/ExportsMain"),
);

const ReportsMain = lazy(
  () =>
    import(
      /* webpackChunkName: "Reporting" */ "../reporting/main/ReportingMain"
    ),
);

const ThreeDayCallBoardMain = lazy(
  () =>
    import(
      /* webpackChunkName: "ThreeDayCallBoard" */ "../callCenter/threedaycallboard/main/ThreeDayCallBoardMain"
    ),
);

const DailyPaceReportMain = lazy(
  () =>
    import(
      /* webpackChunkName: "DailyPaceReport" */ "../callCenter/dailypacereport/main/DailyPaceReportMain"
    ),
);

const BPWMain = lazy(
  () => import(/* webpackChunkName: "BPW" */ "../bpw/main/BPWMain"),
);

const ToolsAdmin = lazy(
  () => import(/* webpackChunkName: "ToolsAdmin" */ "../toolsadmin/ToolsAdmin"),
);

const DMTMain = lazy(
  () => import(/* webpackChunkName: "ToolsAdmin" */ "../dmt/main/DMTMain"),
);
const FinancialSurveyMain = lazy(
  () =>
    import(
      /* webpackChunkName: "ToolsAdmin" */ "../financialSurvey/main/FinancialSurveyMain"
    ),
);
const ProfitReportMain = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfitReport" */ "../profitreport/ProfitReportMain"
    ),
);
const MPWMain = lazy(
  () => import(/* webpackChunkName: "MPW" */ "../mpw/main/MPWMain"),
);

const isBrowserVersionSupported = (userBrowser: any) => {
  // use this link to get updated secure versions of browsers
  // https://github.com/browser-update/browser-update/blob/master/data/browsers.json
  const acceptedVersions = {
    c: "110",
    f: "110",
    s: "16",
    e: "110",
  };
  switch (userBrowser.name) {
    case "Chrome":
      return Number(userBrowser.major) >= Number(acceptedVersions.c);
    case "Safari":
      return Number(userBrowser.major) >= Number(acceptedVersions.s);
    case "Firefox":
      return Number(userBrowser.major) >= Number(acceptedVersions.f);
    case "Edge":
      return Number(userBrowser.major) >= Number(acceptedVersions.e);
    default:
      return false;
  }
};
function App(): ReactElement {
  const lastReleaseVersion = window.localStorage.getItem("lastViewedVersion");

  const [openReleaseDialog, setOpenReleaseDialog] = useState(
    !!(
      !lastReleaseVersion ||
      lastReleaseVersion < process.env.REACT_APP_TOOLS_VERSION
    ),
  );

  const { user } = useAuth0();
  const queryClient = new QueryClient();
  // creates new instance of userAgent where methods can be called to get parsed agent data
  const parser = new UAParser();
  const userAgentBrowser = parser.getBrowser();

  // const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <div className="min-h-screen">
      {!isBrowserVersionSupported(userAgentBrowser) ? (
        <UnsupportedBrowserLandingPage userBrowser={userAgentBrowser} />
      ) : (
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <Router>
              <Auth0ProviderWithHistory>
                <Sentry.ErrorBoundary
                  // TP-1434 MH: this is where the Error Page lives
                  fallback={({ eventId }) => (
                    <ErrorLandingPage eventId={eventId} />
                  )}
                >
                  <Suspense
                    fallback={
                      <LoadingOverlay
                        loading
                        text={
                          process.env.REACT_APP_NODE_ENV !== "development"
                            ? "Loading Nexstar Tools"
                            : "Loading App"
                        }
                      />
                    }
                  >
                    <Auth0Wrapper>
                      {noUserInProd(user as CRMUser) ? null : (
                        <UserProvider>
                          <div id="App" className="pb-[44px]">
                            <CompanyProvider>
                              <DirtyProvider>
                                <EnvironmentWarning />
                                <ReleaseDialog
                                  openReleaseDialog={openReleaseDialog}
                                  setOpenReleaseDialog={setOpenReleaseDialog}
                                />
                                <AppHeader />
                                <main className="bg-NexstarSoftGray flex-grow">
                                  <Routes>
                                    <Route path="/" element={<HomePage />} />
                                    <Route
                                      path="/maintenance"
                                      element={<MaintenanceMode />}
                                    />
                                    <Route
                                      path="/errorPage"
                                      element={
                                        <ErrorLandingPage
                                          eventId={"fromthetestingerrorpage"}
                                        />
                                      }
                                    />
                                    <Route
                                      path="/release"
                                      element={<ReleaseNotes />}
                                    />
                                    <Route
                                      path="profitpricer/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.PROFITPRICER}
                                        >
                                          <ProfitPricerMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="whatifs/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.WHATIFS}
                                        >
                                          <WhatIfsMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="management/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.COMPANYMANAGEMENT}
                                        >
                                          <CompanyManagementMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="imports/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.IMPORT}
                                        >
                                          <ImportsMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="reporting/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.REPORTS}
                                        >
                                          <ReportsMain />
                                        </ProtectedRoute>
                                      }
                                    />

                                    <Route
                                      path="exports/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.EXPORT}
                                        >
                                          <ExportsMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="3daycallboard/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.THREEDAYCALL}
                                        >
                                          <ThreeDayCallBoardMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="dailypace/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.DAILYPACE}
                                        >
                                          <DailyPaceReportMain />
                                        </ProtectedRoute>
                                      }
                                    />

                                    <Route
                                      path="business-planning/*"
                                      element={
                                        <BPWProvider>
                                          <BPWMain />
                                        </BPWProvider>
                                      }
                                    />

                                    <Route
                                      path="marketing-planning/*"
                                      element={
                                        <ProtectedRoute toolEnum={AppsEnum.MPW}>
                                          <MPWMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="marketing/*"
                                      element={
                                        <ProtectedRoute toolEnum={AppsEnum.DMT}>
                                          <DMTMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="financial-survey/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.FINANCIALSURVEY}
                                        >
                                          <FinancialSurveyMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="profitreport/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.PROFITREPORT}
                                        >
                                          <ProfitReportMain />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="tools-admin/*"
                                      element={
                                        <ProtectedRoute
                                          toolEnum={AppsEnum.ADMIN}
                                        >
                                          <ToolsAdmin />
                                        </ProtectedRoute>
                                      }
                                    />
                                    <Route
                                      path="/notifications"
                                      element={<NotificationCenter />}
                                    />
                                    <Route
                                      path="*"
                                      element={<PageNotFound />}
                                    />
                                  </Routes>
                                </main>
                                <ToolsFooter />
                              </DirtyProvider>
                            </CompanyProvider>
                          </div>
                        </UserProvider>
                      )}
                    </Auth0Wrapper>
                  </Suspense>
                </Sentry.ErrorBoundary>
                {/*<NexstarErrorBoundary>*/}
                {/*  */}
                {/*</NexstarErrorBoundary>*/}
              </Auth0ProviderWithHistory>
            </Router>
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      )}
      <ReactTooltip id="my-tooltip" className="nexstar-tooltip" />
    </div>
  );
}

export default App;
