import { Dispatch, SetStateAction } from "react";
import { empty, Notification } from "@nexstar-network/shared-utils";
import { DateTime } from "luxon";
import { RESTAPIErrorType } from "../../../types/ErrorMessageType";
import { handleRESTErrors } from "../../../utils/helpers/RequestHelpers";

export const handleMarkSingleAsRead = (
  ev,
  notificationID: number,
  notifications: Array<Notification>,
  setNotifications: Dispatch<SetStateAction<Array<Notification>>>,
  numberOfUnread: number,
  setNumberOfUnread: Dispatch<SetStateAction<number>>,
  userID: number,
  markNotificationsAsRead: any,
  queryClient: any,
  refetch: any,
  preventDefault: boolean,
  enqueueSnackbar: any,
) => {
  const notificationsMarked = notifications.map(
    (notification: Notification) => {
      if (notification.notificationID === notificationID) {
        return { ...notification, readTime: "read" };
      } else {
        return { ...notification };
      }
    },
  );
  setNumberOfUnread(() => numberOfUnread - 1);
  setNotifications([...notificationsMarked]);
  markNotificationsAsRead.mutate(
    {
      notificationIds: [notificationID],
      userID,
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["getNotifications"], {
          refetchType: "all",
        });
        refetch();
      },
      onError: (saveError: RESTAPIErrorType) => {
        handleRESTErrors(saveError, enqueueSnackbar);
      },
    },
  );
  preventDefault && ev.preventDefault();
};

export const markAllAsRead = (
  ev,
  notifications: Array<Notification>,
  setNotifications: Dispatch<SetStateAction<Array<Notification>>>,
  numberOfUnread: number,
  setNumberOfUnread: Dispatch<SetStateAction<number>>,
  userID: number,
  markNotificationsAsRead: any,
  queryClient: any,
  refetch: any,
  enqueueSnackbar: any,
) => {
  const markedAsRead = [];
  const notificationsToUpdate = { notificationIds: [], userID };
  for (let i = 0; i < notifications.length; i++) {
    markedAsRead.push({ ...notifications[i], readTime: "read" });
    if (empty(notifications[i].readTime)) {
      notificationsToUpdate.notificationIds.push(
        notifications[i].notificationID,
      );
    }
  }
  setNumberOfUnread(0);
  setNotifications([...markedAsRead]);
  markNotificationsAsRead.mutate(notificationsToUpdate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getNotifications"], {
        refetchType: "all",
      });
      refetch();
    },
    onError: (saveError: RESTAPIErrorType) => {
      handleRESTErrors(saveError, enqueueSnackbar);
    },
  });
  ev.preventDefault();
};

export const orderedNotifications = (notifications: Notification[]) => {
  if (notifications) {
    return Object.values(notifications).sort(
      (a: any, b: any) =>
        // @ts-ignore
        DateTime.fromISO(b.created_at) - DateTime.fromISO(a.created_at),
    );
  }
};
