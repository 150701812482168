import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  avatarOrDefault,
  CanAccessAdminPage,
  CRMUser,
  isNexstarEmployee,
  nicknameOrDefault,
  RoleIdOrDefault,
} from "../../utils/helpers/UserHelper";
import InNewTabIcon from "../../utils/components/icons/InNewTabIcon";
import UserDropdownLink from "./UserDropdownLink";
import Auth0LogoutButton from "../auth/Auth0LogoutButton";
import UserEmulationDropdown from "../toolsadmin/UserEmulationDropdown";
import Version from "../../utils/components/Version";
import * as Sentry from "@sentry/react";
import Toggle from "../../utils/components/tailwind/Toggle";
import CoachingTeamModal from "../../utils/components/CoachingTeamModal";
import { useUserContext } from "../context/user/UserContext";
import { useCompanyContext } from "../context/company/CompanyContext";
import { useDirtyContext } from "../context/dirty/DirtyContext";

type UserDropDownType = {
  user: CRMUser;
};

export default function UserDropdownMenu({
  user,
}: UserDropDownType): JSX.Element {
  const { getUser } = useUserContext();
  const { memberCoaches } = useCompanyContext();
  const { handleOpenDirty } = useDirtyContext();
  const nickName = nicknameOrDefault(user as CRMUser);
  const avatar = avatarOrDefault(user as CRMUser);
  const navigate = useNavigate();
  const isCoach = RoleIdOrDefault(getUser()) === 4;
  const [coachView, setCoachView] = useState(false);
  const [coachModalOpen, setCoachModalOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("coachView") === "enabled") {
      setCoachView(true);
    }
    if (isCoach) {
      if (localStorage.getItem("coachView") === null) {
        localStorage.setItem("coachView", "enabled");
        setCoachView(true);
      }
    }
  }, []);

  const navigateCRM = () => {
    handleOpenDirty(() => (window.location.href = "https://nexstarcrm.com"));
  };

  const navigateMemberSite = () => {
    handleOpenDirty(
      () => (window.location.href = "https://member.nexstarnetwork.com"),
    );
  };

  const navigateHelpDesk = () => {
    handleOpenDirty(
      () =>
        (window.location.href =
          "https://nexstarnetwork.atlassian.net/servicedesk/customer/portals"),
    );
  };
  const handleCoachView = () => {
    if (coachView) {
      setCoachView(false);
      localStorage.setItem("coachView", "disabled");
      location.reload();
    } else {
      setCoachView(true);
      localStorage.setItem("coachView", "enabled");
      location.reload();
    }
  };
  return (
    <>
      <Popover className="relative flex align-center ml-4">
        {({ close }) => (
          <>
            <Popover.Button
              id="userDrowdownButton"
              className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
            >
              <div className="flex gap-2 items-center w-max">
                <img
                  id="userImage"
                  src={avatar}
                  alt={nickName}
                  className="inline-block h-7 w-7 rounded-full"
                />
              </div>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-5 flex w-screen max-w-max">
                <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-sm bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                  <div className="flex align-center gap-2 px-4 py-2 bg-NexstarBlue-light">
                    <img
                      id="userImage"
                      src={avatar}
                      alt={nickName}
                      className="inline-block h-7 w-7 rounded-full"
                    />
                    <p
                      id="userNickname"
                      className="flex align-center text-white text-base font-bold"
                    >
                      {nickName}
                    </p>
                  </div>
                  {(process.env.REACT_APP_NODE_ENV === "qa" ||
                    process.env.REACT_APP_NODE_ENV === "staging") &&
                  RoleIdOrDefault(user) === 1 ? (
                    <UserDropdownLink
                      id="errorTesterButton"
                      // @ts-ignore
                      onClick={(ev: any) => {
                        try {
                          // eslint-disable-next-line no-console
                          console.log(
                            // eslint-disable-next-line no-console
                            ev.asdf.forEach((a: any) => console.log(a)),
                          );
                        } catch (e) {
                          // eslint-disable-next-line no-console
                          console.log(Sentry.captureException(e));
                        }
                      }}
                    >
                      Error Tester
                      <InNewTabIcon color="gray" />
                    </UserDropdownLink>
                  ) : null}
                  <UserDropdownLink id="myPageButton" onClick={navigateCRM}>
                    My Page
                    <InNewTabIcon color="gray" />
                  </UserDropdownLink>
                  <UserDropdownLink
                    id="memberSiteNav"
                    onClick={navigateMemberSite}
                  >
                    Member Site
                    <InNewTabIcon color="gray" />
                  </UserDropdownLink>
                  <UserDropdownLink
                    id="releaseNotesNav"
                    onClick={() => {
                      close();
                      navigate("/release");
                    }}
                  >
                    Release Notes
                  </UserDropdownLink>
                  {memberCoaches ? (
                    <UserDropdownLink
                      id="coachingTeamNav"
                      onClick={() => {
                        setCoachModalOpen(true);
                        close();
                      }}
                    >
                      Contact Your Coaching Team
                    </UserDropdownLink>
                  ) : null}
                  {CanAccessAdminPage(user as CRMUser) ? (
                    <UserDropdownLink
                      id="administrationNav"
                      onClick={() => {
                        close();
                        navigate("/tools-admin");
                      }}
                    >
                      Administration
                    </UserDropdownLink>
                  ) : null}
                  {isNexstarEmployee(user as CRMUser) ? (
                    <UserDropdownLink
                      id="helpDeskNav"
                      onClick={navigateHelpDesk}
                    >
                      Help Desk
                      <InNewTabIcon color="gray" />
                    </UserDropdownLink>
                  ) : null}
                  <Auth0LogoutButton />
                  {RoleIdOrDefault(user) === 1 ? (
                    <UserEmulationDropdown user={user} />
                  ) : null}
                  {isNexstarEmployee(user as CRMUser) ? (
                    <div className="py-2 px-4 bg-NexstarBlue-light">
                      <Toggle
                        id="coachView_toggle"
                        checked={coachView}
                        name="coachView"
                        label="Coach View"
                        onChange={handleCoachView}
                      />
                    </div>
                  ) : null}
                  <Version />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <CoachingTeamModal open={coachModalOpen} setOpen={setCoachModalOpen} />
    </>
  );
}
