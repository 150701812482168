import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useUserContext } from "../components/context/user/UserContext";
import { addMessage } from "./helpers/MessagingHelper";
import { RoleIdOrDefault } from "./helpers/UserHelper";
import { AppsEnum } from "@nexstar-network/shared-utils";

function ProtectedRoute({
  toolEnum,
  redirectPath = "/",
  children,
}: {
  toolEnum: AppsEnum;
  redirectPath?: string;
  children: ReactElement;
}) {
  const { userToolsAccess, getUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  if (
    (userToolsAccess && toolEnum in userToolsAccess) ||
    RoleIdOrDefault(getUser()) === 1
  )
    return children;
  addMessage("ACCESS_DENIED", "warning", enqueueSnackbar);
  return <Navigate to={redirectPath} replace />;
}

export default ProtectedRoute;
