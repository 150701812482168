import React, { useState } from "react";
import { useUserContext } from "../context/user/UserContext";
import {
  CRMUser,
  ProfileIdOrDefault,
  RoleIdOrDefault,
  UserNameOrDefault,
} from "../../utils/helpers/UserHelper";
import Accordion from "../../utils/components/tailwind/Accordion";
import { NexstarTextField } from "../../utils/components/tailwind/NexstarTextField";
import Button from "../../utils/components/tailwind/Button";

function UserEmulationDropdown({ user }: { user: CRMUser }): JSX.Element {
  const { emulatedUser, handleCancelEmulation, handleEmulatingUser } =
    useUserContext();

  const [profileId, setProfileId] = useState("");
  if (!(RoleIdOrDefault(user) === 1 || ProfileIdOrDefault(user) === 581))
    return null;
  return (
    <Accordion
      title={
        emulatedUser ? (
          <p>{`Emulating ${UserNameOrDefault(emulatedUser)}`}</p>
        ) : (
          <p>Emulate User</p>
        )
      }
      rounded={false}
      id="userEmulation_Accordion"
    >
      <>
        {!emulatedUser ? (
          <div className="flex gap-4 items-end">
            <NexstarTextField
              id="emulationProfile"
              name="emulationProfile"
              label="Emulation Profile Id"
              value={profileId}
              onChange={(event) => setProfileId(event.target.value)}
              textAlign="text-center"
            />
            <Button
              id="emulateUser"
              bgColor="bg-NexstarBlue"
              onClick={() => handleEmulatingUser(profileId)}
            >
              Emulate
            </Button>
          </div>
        ) : null}
        {emulatedUser ? (
          <Button id="cancelEmulation" onClick={() => handleCancelEmulation()}>
            Cancel
          </Button>
        ) : null}
      </>
    </Accordion>
  );
}

export default UserEmulationDropdown;
