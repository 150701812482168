import React, { ReactElement, useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../../context/user/UserContext";
import {
  CompanyIdOrDefault,
  CRMUser,
  RoleIdOrDefault,
  UserIdOrDefault,
} from "../../../utils/helpers/UserHelper";
import { UseQueryType } from "../../../types/ErrorMessageType";
import {
  getNotificationsForMember,
  markAsRead,
} from "../utils/notificationRequests";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import InformationIcon from "../../../utils/components/icons/InformationIcon";
import ButtonToolTip from "../../../utils/components/tailwind/ButtonToolTip";
import NotificationToggle from "./NotificationToggle";
import BellIcon from "../../../utils/components/icons/BellIcon";
import { DateTime } from "luxon";
import { empty, Notification } from "@nexstar-network/shared-utils";
import {
  handleMarkSingleAsRead,
  markAllAsRead,
  orderedNotifications,
} from "../utils/notificationHelpers";
import { enqueueSnackbar } from "notistack";

export default function NotificationDropdownMenu(): ReactElement {
  const { getAccessTokenSilently } = useAuth0();
  const { getUser, selectedCompany } = useUserContext();
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const roleId = RoleIdOrDefault(user as CRMUser);
  const userId = UserIdOrDefault(user as CRMUser);
  const memberId = CompanyIdOrDefault(getUser(), selectedCompany).toString();
  const [displayOnlyUnRead, setDisplayOnlyUnRead] = useState<boolean>(true);
  const [numberOfUnread, setNumberOfUnread] = useState(0);
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  const {
    data: notificationData,
    isLoading: notificationLoading,
    refetch,
    error: notificationsError,
  }: UseQueryType<Array<Notification>> = useQuery({
    queryKey: ["getNotifications"],
    queryFn: () =>
      getNotificationsForMember(
        memberId,
        roleId,
        userId,
        getAccessTokenSilently,
      ),
  });
  const markNotificationsAsRead = useMutation({
    mutationFn: (notificationsToMark: any) =>
      markAsRead(notificationsToMark, getAccessTokenSilently),
  });

  useEffect(() => {
    if (notificationData && notificationData && !notificationLoading) {
      let numberOfUnreadNotifications = 0;
      for (let i = 0; i < notificationData.length; i++) {
        if (empty(notificationData[i].readTime)) {
          numberOfUnreadNotifications++;
        }
      }
      setNumberOfUnread(numberOfUnreadNotifications);
      setNotifications(notificationData);
    }
  }, [notificationData]);

  const handleItemClick = (ev) => {
    ev.stopPropagation();
  };

  if (notificationsError) throw new Error(notificationsError.message);
  const DisplayNotifications = (notificationsToDisplay: any[]) => {
    if (notifications && notifications.length > 0 && !notificationLoading) {
      const displayItems: Notification[] = [];
      let displayCount = 0;
      notificationsToDisplay.map((notification) => {
        if (displayOnlyUnRead) {
          if (empty(notification.readTime)) {
            if (displayCount < 9) {
              displayItems.push(notification);
              displayCount++;
            }
          }
        } else {
          if (displayCount < 9) {
            displayItems.push(notification);
          }
        }
      });
      if (displayItems.length) {
        return displayItems.map((notification) => (
          <Menu.Item
            as="a"
            key={`${notification.notificationID}-item`}
            href="/notifications"
            className="pt-4 pb-3 flex grow"
          >
            {({ active }) => (
              <div
                key={`${notification.notificationID}-button`}
                className={`${
                  active ? "bg-NexstarSoftGray" : "text-gray-900"
                } w-full items-center px-4 py-1 text-sm h-16 border-0`}
              >
                <div className="flex flex-col-3 justify-start">
                  <div className="pr-3 mt-[-2]">
                    <InformationIcon color="NexstarSkyBlue" size="medium" />
                  </div>
                  <div
                    className={`flex-row flex-wrap text-[17px] font-[450] ${
                      !empty(notification.readTime)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    } w-full text-left justify-between`}
                  >
                    {notification.notificationText}
                  </div>
                  {empty(notification.readTime) ? (
                    <ButtonToolTip tooltip="Mark as read">
                      <button
                        className="h-[24px] w-[24px] hover:bg-NexstarGray-dark hover:rounded-full"
                        onClick={(ev) =>
                          handleMarkSingleAsRead(
                            ev,
                            notification.notificationID,
                            notifications,
                            setNotifications,
                            numberOfUnread,
                            setNumberOfUnread,
                            userId,
                            markNotificationsAsRead,
                            queryClient,
                            refetch,
                            true,
                            enqueueSnackbar,
                          )
                        }
                      >
                        <div className="bg-NexstarBlue-dark rounded-full w-[7px] h-[7px] font-bold text-white text-xs ml-2" />
                      </button>
                    </ButtonToolTip>
                  ) : (
                    <button className="h-[24px] w-[24px]">
                      <div className="bg-NexstarGray-dark rounded-full w-[7px] h-[7px] font-bold text-white text-xs ml-2" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col-1 justify-start">
                  <div
                    className={`flex-row w-full text-[12px] text-left ml-10 ${
                      !empty(notification.readTime)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    }`}
                  >
                    Updated At:{" "}
                    <span>
                      {DateTime.fromISO(notification.created_at)
                        .toLocal()
                        .toFormat("MM-dd-yyyy")}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Menu.Item>
        ));
      }
    }
    return (
      <Menu.Item disabled as="a" key="no_notifications">
        <div className="h-[125px] pt-10 text-xl text-black text-center">
          No Unread Notifications
        </div>
      </Menu.Item>
    );
  };
  return (
    <div
      id="TopBarNotifications"
      className="relative flex align-center ml-4 over"
    >
      <Menu>
        <>
          <div>
            <Menu.Button className="inline-flex w-full justify-center mt-[-15px]">
              <BellIcon size="medium" className="ml-2" />
              {numberOfUnread > 0 ? (
                <div className="bg-red-600 rounded-full w-[20px] h-[20px] font-bold text-white text-xs pt-[2px] text-center ml-[-10px] mr-[-8px]">
                  {numberOfUnread}
                </div>
              ) : null}
            </Menu.Button>
          </div>
          <Menu.Items
            onClick={(ev) => handleItemClick(ev)}
            className="absolute z-10 right-0 mt-5 w-[650px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Menu.Item disabled>
              <div className="flex justify-between font-bold h-16 text-black text-center pt-2">
                <div className="ml-3 mt-2 text-2xl">Notifications</div>
                <div className="justify-end text-black mr-3 mt-3">
                  <NotificationToggle
                    onChange={() => setDisplayOnlyUnRead(!displayOnlyUnRead)}
                    checked={displayOnlyUnRead}
                    name="Mark All As Read"
                    label="Only Show Unread"
                  />
                </div>
              </div>
            </Menu.Item>
            <div className="flex text-sm justify-end h-5 mr-3">
              <Menu.Item>
                <button
                  className="h-5 hover:text-NexstarOrange"
                  onClick={(ev) =>
                    markAllAsRead(
                      ev,
                      notifications,
                      setNotifications,
                      numberOfUnread,
                      setNumberOfUnread,
                      userId,
                      markNotificationsAsRead,
                      queryClient,
                      refetch,
                      enqueueSnackbar,
                    )
                  }
                >
                  Mark all as read
                </button>
              </Menu.Item>
            </div>
            {DisplayNotifications(orderedNotifications(notifications))}
          </Menu.Items>
        </>
      </Menu>
    </div>
  );
}
