import axios from "axios";

/**
 *
 * @param memberId
 * @param roleId
 * @param userId
 * @param getAccessTokenSilently
 */
export async function getNotificationsForMember(
  memberId: string,
  roleId: number,
  userId: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}notifications/member/${memberId}/roleId/${roleId}/userId/${userId}`,
    method: "get",
  }).then((res) => res.data);
}

/**
 *
 * @param getAccessTokenSilently
 */
export async function getAllNotifications(
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}notifications/all-notifications`,
    method: "get",
  });
}

/**
 *
 * @param notificationsToUpdate
 * @param getAccessTokenSilently
 */
export async function markAsRead(
  notificationsToUpdate: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}notifications/read-notifications`,
    data: notificationsToUpdate,
    method: "put",
  });
}

/**
 *
 * @param notificationsToUpdate
 * @param getAccessTokenSilently
 */
export async function newNotification(
  notificationsToUpdate: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}notifications/new-notification`,
    data: notificationsToUpdate,
    method: "put",
  });
}

/**
 *
 * @param notificationsToUpdate
 * @param getAccessTokenSilently
 */
export async function updateNotification(
  notificationsToUpdate: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}notifications/update-notification`,
    data: notificationsToUpdate,
    method: "put",
  });
}

/**
 *
 * @param notificationsToDelete
 * @param getAccessTokenSilently
 */
export async function deleteNotification(
  notificationsToDelete: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}notifications/delete-notifications`,
    data: notificationsToDelete,
    method: "delete",
  });
}
